.list {
  background: transparent;
}

.card-short {
  height: auto;
  width: auto;
}

.card-long {
  height: 80px;
  width: auto;
}

.grid-fonts {
  color: black;
  font-size: 16px !important;
}

.search-fixed {
  background: #cfe8e2;
  position: sticky;
  width: auto;
  padding-top: 15px;
  padding-bottom: 5px;
  top: 0px;
  z-index: 2;
}
