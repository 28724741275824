@import "../../theme/listing.scss";
@import "../../theme/detail.scss";

.edit-card {
  height: 74vh !important;
}

.list-edit{
  padding-bottom: 10vh;
}

.list-detail {
  overflow: scroll;
  width: 100%;
  height: 100% !important;
  padding-bottom:15vh !important
}
.list-row {
  overflow: scroll;
  width: 100%;
  height: 45vh !important;
}

.fullcard {
  height: 85% !important;
}

.col-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.row-price {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.price-fixed {
  position: absolute;
  margin: 0;
  width: 100%;
  bottom: 0px;
  z-index: 3;
}

.price-card {
  margin: 0;
}

.edit-grid {
  display: flex;
  flex-direction: row;
}

.button-div {
  padding-top: 3px;
  width: fit-content;
  height: fit-content;
}

.buttons-center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.edit-row-div {
  width: 100%;
  padding-right: 10px;
}
