@import "../../theme/listing.scss";
@import "../../theme/detail.scss";

.list-detail {
  width: 100%;
  height:calc(40vh - 80px);
}

.col-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.row-price {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.price-fixed {
  position: absolute;
  margin: 0;
  width: 100%;
  bottom: 0px;
  z-index: 2;
}

.price-card {
  margin: 0;
}

.edit-grid {
  display: flex;
  flex-direction: row;
}

.button-div {
  padding-top: 3px;
  width: fit-content;
  height: fit-content;
}

.buttons-center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.edit-row-div {
  width: 100%;
  padding-right: 10px;
}

.distribution {
  font-size: 20px;
  font-weight: bold;
  color: "black";
  height: auto;
}
