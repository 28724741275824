.login {
  --background: radial-gradient(
    circle,
    rgba(1, 139, 136, 1) 0%,
    rgba(0, 120, 136, 1) 100%
  ) !important;
}

.trailsText {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 15%;
  width: 100%;
  line-height: 80px;
  color: black;
  font-size: 6em;
  font-weight: 800;
  letter-spacing: -0.05em;
  will-change: transform, opacity;
  overflow: hidden;
}
