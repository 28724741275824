@import "../../../theme/listing.scss";
@import "../../../theme/detail.scss";

.form {
  background: #cfe8e2;
  width: auto;
  padding-top: 15px;
  padding-bottom: 5px;
  top: 0px;
  z-index: 2;
  overflow-y: scroll;
}

.create-list{
  margin-bottom: 50px;
}