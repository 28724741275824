.detail-grid {
  font-size: 18px;
  .first-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: black;
  }
}

.col-center {
  display: flex;
  justify-content: center;
}
.col-left {
  display: flex;
  justify-content: left;
}
.col-right {
  display: flex;
  justify-content: right;
}
