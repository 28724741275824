.right-button {
  margin: 0;
}

.search-grid {
  padding: 0;
}

.search-card {
  width: auto;
}

.search-input {
  margin-left: 20px;
  font-size: 20px !important;
}
